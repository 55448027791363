<template>
  <div class="menu-mgmt">
    <div class="main-body">
      <el-form
        size="mini"
        :inline="true"
        :model="params"
        class="demo-form-inline"
      >
        <el-form-item label="平台类型">
          <el-select ref="paramsPlatformType" v-model="params.platformType" placeholder="请选择平台类型">
            <el-option label="web应用" value="3"></el-option>
            <!-- <el-option label="ios应用" value="1"></el-option>
            <el-option label="android应用" value="2"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="recordList"
        style="width: 100%;margin-bottom: 20px;"
        class="table-custom"
        cell-class-name="text-center"
        row-key="number"
        :header-row-style="headerStyle"
        :tree-props="{ children: 'subMenuList', hasChildren: 'true' }"
      >
        <el-table-column
          prop="number"
          label="编号"
          width="230"
        ></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="path" label="路径"></el-table-column>
        <el-table-column prop="icon" label="图标"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="text" size="mini"
              >编辑</el-button
            >
            <el-button @click="handleDelete(scope.row)" type="text" size="mini"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="opearteType == '1' ? '新增菜单' : '编辑菜单'"
      :visible.sync="dialogTableVisible"
      width="500px"
      :show-close="false"
    >
      <el-form ref="form" :model="editRecord" label-width="80px">
        <el-form-item label="平台类型">
          <el-select
            :popper-append-to-body="false"
            v-model="editRecord.platformType"
            placeholder="请选择平台类型"
            @change="queryRecordList(editRecord.platformType, null)"
          >
            <el-option label="web应用" value="3"></el-option>
            <!-- <el-option label="ios应用" value="1"></el-option>
            <el-option label="android应用" value="2"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="上级菜单">
          <el-cascader
            v-model="editRecord.parentMenuNos"
            :options="menuOptions"
            :props="{
              checkStrictly: true,
              value: 'number',
              label: 'name',
              children: 'subMenuList'
            }"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="名称">
          <el-input
            v-model="editRecord.name"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="路径">
          <el-input
            v-model="editRecord.path"
            placeholder="请输入路径"
          ></el-input>
        </el-form-item>
        <el-form-item label="图标">
          <el-input
            v-model="editRecord.icon"
            placeholder="请输入图标名称"
            :suffix-icon="editRecord.icon"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="editRecord.sort"
            placeholder="请输入排序"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <el-button @click="closeDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMenuList,
  createOrUpdateMenu,
  deleteMenu
} from "@/api/jurisdiction";

export default {
  data() {
    return {
      headerStyle: {
        color: "#333"
      },
      recordList: [],
      dialogTableVisible: false,
      opearteType: "",
      item: {},
      platformType: "1",
      parentMenuNo: "",
      params: {
        platformType: "3"
      },
      editRecord: {},
      menuOptions: []
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.paramsPlatformType.blur()
        _this.handleQuery()
      }
    };
    this.queryRecordList(this.params.platformType, "1");
  },
  methods: {
    /**获取菜单列表 */
    async queryRecordList(platformType, type) {
      let params = {
        platformType: platformType || "3",
        recursion: true
      };
      const res = await getMenuList(params);
      if (type == 1) {
        this.recordList = res.ldata;
      } else {
        this.menuOptions = res.ldata;
      }
    },

    handleAdd() {
      this.opearteType = "1";
      this.editRecord = { platformType: this.params.platformType };
      this.menuOptions = [];
      if (this.params.platformType) {
        this.queryRecordList(this.params.platformType, null);
      }
      this.dialogTableVisible = true;
    },

    onSubmit() {
      this.createOrUpdate();
    },

    async createOrUpdate() {
      if (this.editRecord.parentMenuNos && this.editRecord.parentMenuNos.length) {
        this.editRecord.parentMenuNo = this.editRecord.parentMenuNos[ this.editRecord.parentMenuNos.length - 1];
      }
      const params = {
        ...this.editRecord,
      }
      delete params.parentMenuNos
      delete params.subMenuList
      await createOrUpdateMenu(params);
      this.$message.success("操作成功");
      this.closeDialog();
      this.$router.go(0)
      return
      this.queryRecordList(this.params.platformType, "1");
    },
    closeDialog() {
      this.dialogTableVisible = false;
      this.item = {};
    },
    handleEdit(row) {
      this.opearteType = "2";
      this.dialogTableVisible = true;
      this.menuOptions = [];
      if (this.params.platformType) {
        this.queryRecordList(this.params.platformType, null);
      }
      this.editRecord = JSON.parse(JSON.stringify(row));
      this.editRecord.parentMenuNos = [this.editRecord.parentMenuNo];
    },

    handleDelete(item) {
      this.$confirm("是否确认删除？").then(() => {
        this.delete(item.number);
      });
    },

    async delete(number) {
      const params = {
        number: number
      };
      const res = await deleteMenu(params);
      this.$message.success("操作成功");
      this.queryRecordList(this.params.platformType, "1");
    },

    handleQuery() {
      this.beginIndex = 0;
      this.queryRecordList(this.params.platformType, "1");
    },
    showErrorMessage(message) {
      if (message == "0001") {
        this.$message.warning("登录超时，请重新登录");
      } else if (message == "0004") {
      } else {
        this.$message.error(message);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.main-body {
  .title {
    font-size: 20px;
    padding: 30px 0 40px;
  }

  .list {
    line-height: 30px;
    margin: 20px 30px 10px;

    .name-label {
      float: left;
    }

    .value-label {
      display: inline-block;
      height: 30px;
    }

    .date-global {
      width: 185px;
    }

    .list-input {
      width: 180px;
    }

    .fixed-width-100 {
      width: 100px;
    }

    .fixed-width-200 {
      width: 200px;
    }

    .fixed-width-300 {
      width: 300px;
    }

    .fixed-width-400 {
      width: 400px;
    }
  }

  .table-custom {
    border-bottom: 1px solid #cdcdcd;

    .text-btn {
      width: 50px;
      height: 24px;
      line-height: 24px;
      color: #4949ff;
      display: inline-block;
      background-color: #67aff6;
      cursor: pointer;
    }

    .text-btn.disabled {
      border: #67aff6;
      background-color: #fff;
      cursor: default;
    }

    .fixed-width-75 {
      width: 75px;
    }

    .fixed-width-100 {
      width: 100px;
    }

    .fixed-width-300 {
      width: 300px;
    }

    .mt4 {
      margin-top: 4px;
    }
  }
}
</style>
