import axios from '../js/ajax'
/** 菜单列表 */
export const getMenuList = (params) => axios.get('/admin/application/menu/findByParentMenuNoAndPlatformType', {params, loading: true})
/**新增/ 更新菜单权限 */
export const createOrUpdateMenu = (params) => axios.post('/admin/application/menu/createOrUpdate', params)
/** 删除菜单 */
export const deleteMenu = (params) => axios.get('/admin/application/menu/delete', {params});
/** 获取服务资源列表 */
export const getServiceList = (params) =>  axios.get('/admin/serviceResource/group/findAll', {params, loading: true})
/*新增和编集服务资源分组*/
export const addGroupService = (params) => axios.post('/admin/serviceResource/group/createOrUpdate', params);
/*新增服务资源*/
export const addService = (params) => axios.post('/admin/serviceResource/createOrUpdate', params);
/*删除服务资源分组*/
export const delGroupService = (params) => axios.get('/admin/serviceResource/group/delete', {params});
/*删除服务资源*/
export const delService = (params) => axios.get('/admin/serviceResource/delete', {params});
/** 获取角色权限列表 */
export const getRoleList = (params) => axios.get('/admin/authorityScheme/getAllSchemeForUser', {params, loading: true});
/**新增和更改角色权限 */
export const createOrUpdateRoleJur = (params) => axios.post('/admin/authorityScheme/createOrUpdate',params)
/** 角色权限配置菜单和权限*/
export const createOrUpdateRoleMenuJur = (params) => axios.postJson('/admin/authorityScheme/relation/createOrUpdate', params);
/**角色权限已配置权限 */
export const getAllResourceByNumber = (params) => axios.get('/admin/serviceResource/findByAuthoritySchemeNo', {params});
// 角色已配置菜单
export const getAllMenuByNumber = (params) =>  axios.get('/admin/application/menu/findByAuthoritySchemeNo', {params});
/** 删除角色 */
export const delRole =  (params) => axios.get('/admin/authorityScheme/delete', {params});
/** 获取用户权限 */
export const getUserList = (params) => axios.get('/client/user/listAccount', {params}); 
/** 获取用户配置权限 */
export const getUserJur = (params) => axios.get('/admin/authorityScheme/getAuthoritySchemeForUser', {params}); 
/**添加或更改用户权限 */
export const createOrUpdateUserJur = (params) => axios.get('/admin/user/userAccount/authorityScheme/createOrUpdate', {params}); 

/** 获取当前用户登录权限 */
export const getUserRouter = (params) => axios.get('/admin/application/menu/getAvailable', {params})